import Tagify from '@yaireo/tagify'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import { FileChecksum } from '@rails/activestorage/src/file_checksum';
import { BlobRecord} from '@rails/activestorage/src/blob_record';

const Uppy = require('uppy');
const {Plugin} = require('@uppy/core');

import {htmlToElement, insertBefore} from '../controllers/helpers/dom';

window.hideGuides = function() {
  document.getElementById('horizontal-guide-top').classList.add('hidden');
  document.getElementById('horizontal-guide-bottom').classList.add('hidden');
  document.getElementById('vertical-guide-left').classList.add('hidden');
  document.getElementById('vertical-guide-right').classList.add('hidden');
  document.getElementById('guide-x1').classList.add('hidden');
  document.getElementById('guide-x2').classList.add('hidden');
  document.getElementById('guide-y1').classList.add('hidden');
  document.getElementById('guide-y2').classList.add('hidden');
};

window.tagifyInputs = function() {
  var tagged_inputs = document.querySelectorAll('input.taggify');

  for (let tagged_input of tagged_inputs) {
    new Tagify(tagged_input);
  }
}

window.datePicker = function() {
  var date_inputs = document.querySelectorAll('input.date-picker:not(.flatpickr-input)');

  for (let date_input of date_inputs) {
    flatpickr(date_input, {});
  }
}

window.hideContextMenus = function() {
  let contextMenus = document.querySelectorAll('.context-menu');
  contextMenus.forEach((context_menu) => {
    context_menu.classList.add('hidden');
  });

  let contextMenusV2 = document.querySelectorAll('.contextMenu');
  contextMenusV2.forEach((contextMenu) => {
    contextMenu.classList.add('hidden');
  });

  let activeItems = document.querySelectorAll('.open-context-menu');
  activeItems.forEach((activeItem) => {
    activeItem.classList.remove('open-context-menu');
  });

  let zoomMenu = document.querySelector('#zoomMenu');
  if (zoomMenu) zoomMenu.classList.add('hidden');

  Array.from(document.querySelectorAll('.commentMenu')).forEach((commentMenu) => {
    commentMenu.classList.add('hidden');
  });

  let commentFilterMenu = document.querySelector('#commentFilterMenu');
  if (commentFilterMenu) commentFilterMenu.classList.add('hidden');

  let presentationMenu = document.querySelector('#presentationMenu');
  if (presentationMenu) presentationMenu.classList.add('hidden');

  // Hover menu triggered on leftbar
  document.querySelectorAll('.button .hoverMenu').forEach((item) => item.classList.add('hidden'));
}

window.hideColorPicker = function() {
  const colorPicker = document.querySelector('.colorPicker');
  if(colorPicker) {
    colorPicker.classList.add('hidden');
  }
}

window.unselectAllItems = function() {
  var items = document.querySelectorAll('.draggable-item-container.selected');
  for (let item of items) {
    item.classList.remove('selected');
  }
}

window.htmlDecode = function(input) {
  var e = document.createElement('div');
  e.innerHTML = input;
  if (e.childNodes.length > 0)
    return e.childNodes[0].nodeValue;
  else
    return '';
}

window.activateSlimSelect = function() {
  var x, i, j, selElmnt, a, b, c;
  /* Look for any elements with the class "custom-select": */
  x = document.getElementsByClassName("custom-select");
  for (i = 0; i < x.length; i++) {
    if (x[i].querySelector('.select-selected')) { continue; }

    selElmnt = x[i].getElementsByTagName("select")[0];
    /* For each element, create a new DIV that will act as the selected item: */
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    if(!selElmnt) continue;

    // Add icon
    let icon = x[i].querySelector('i.icon');
    let noPreview = x[i].classList.contains('no-preview');
    if (icon) {
      icon.classList.remove('hidden');
      if (noPreview) {
        a.innerHTML = icon.outerHTML;
      } else {
        a.innerHTML = icon.outerHTML + window.htmlDecode(selElmnt.options[selElmnt.selectedIndex].innerHTML);
      }
      icon.classList.add('hidden');
    } else {
        try {
            a.innerHTML = window.htmlDecode(selElmnt.options[selElmnt.selectedIndex].innerHTML);
        } catch (e) {
            console.log(e, 'Select box error')
        }
    }

    x[i].appendChild(a);
    /* For each element, create a new DIV that will contain the option list: */
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");

    // Input for search
    if (x[i].classList.contains('searchable')) {
      let searchContainer = document.createElement('DIV');
      let searchInput = document.createElement('INPUT');
      searchInput.classList.add('custom-select-search');
      searchInput.classList.add('w-full');
      searchInput.classList.add('border');
      searchInput.classList.add('border-lightest-gray');
      searchInput.classList.add('rounded');
      searchInput.classList.add('p-2');
      searchContainer.appendChild(searchInput);
      b.appendChild(searchContainer);

      searchInput.addEventListener("keyup", function(e) {
        let records = e.target.closest('.select-items').querySelectorAll('div.searchable');
        let filter = e.target.value.toUpperCase();

        records.forEach((record) => {
          if (record.innerHTML.toUpperCase().indexOf(filter) > -1) {
            record.classList.remove('hidden');
          } else {
            record.classList.add('hidden');
          }
        });
      });
    }

    for (j = 0; j < selElmnt.length; j++) {
      /* For each option in the original select element,
      create a new DIV that will act as an option item: */
      c = document.createElement("DIV");
      c.classList.add('searchable');
      c.innerHTML = window.htmlDecode(selElmnt.options[j].innerHTML);
      c.setAttribute('data-value', selElmnt.options[j].value);

      c.addEventListener("click", function(e) {
        /* When an item is clicked, update the original select box,
        and the selected item: */
        var y, i, k, s, h;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        h = this.parentNode.previousSibling;
        for (i = 0; i < s.length; i++) {
          if (s.options[i].value == this.getAttribute('data-value')) {
            s.selectedIndex = i;
            let icon = e.target.closest('.custom-select').querySelector('i.icon');
            let noPreview = e.target.closest('.custom-select').classList.contains('no-preview');
            if (icon) {
              icon.classList.remove('hidden');
              if (noPreview) {
                h.innerHTML = icon.outerHTML;
              } else {
                h.innerHTML = icon.outerHTML + this.innerHTML;
              }
              icon.classList.add('hidden');
            } else {
              h.innerHTML = this.innerHTML;
            }

            var event = new Event('change', { bubbles: true, cancelable: true });
            s.dispatchEvent(event);

            y = this.parentNode.getElementsByClassName("same-as-selected");
            for (k = 0; k < y.length; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function(e) {
      /* When the select box is clicked, close any other select boxes,
      and open/close the current select box: */
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }

  function closeAllSelect(elmnt) {
    if (elmnt.target) {
      if (elmnt.target.classList.contains('custom-select-search')) { return; }
    }

    /* A function that will close all select boxes in the document,
    except the current select box: */
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }

  /* If the user clicks anywhere outside the select box,
  then close all select boxes: */
  document.addEventListener("click", closeAllSelect);
}

window.activateFroalaEditor = function(customClass = '', studioId = '') {
  var changeDirection = function (dir, align) {
    // Wrap block tags.
    this.selection.save();
    this.html.wrap(true, true, true, true);
    this.selection.restore();

    // Get blocks.
    var elements = this.selection.blocks();

    // Save selection to restore it later.
    this.selection.save();

    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      if (element != this.el) {
        this.$(element)
          .css('direction', dir)
          .css('text-align', align)
          .removeClass('fr-temp-div');
      }
    }

    // Unwrap temp divs.
    this.html.unwrap();

    // Restore selection.
    this.selection.restore();
  }

  FroalaEditor.DefineIcon('rightToLeft', {NAME: 'arrow-left', PATH: 'M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z'})
  FroalaEditor.RegisterCommand('rightToLeft', {
    title: 'RTL',
    icon: 'rightToLeft',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      changeDirection.apply(this, ['rtl', 'right']);
    }
  })

  FroalaEditor.DefineIcon('leftToRight', {NAME: 'arrow-right', PATH: 'M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z'});
  FroalaEditor.RegisterCommand('leftToRight', {
    title: 'LTR',
    icon: 'leftToRight',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      changeDirection.apply(this, ['ltr', 'left']);
    }
  })

  new FroalaEditor('.fr-editor', {
    editorClass: customClass,
    height: 300,
    key: 'HQD1uB3A1D4C1A1D2lFe1a1PVWEc1Fd1XHTHc1THMMe1NCb1tA1A1A1E1H4A1B3B1D7C4==',
    attribution: false,
    quickInsertEnabled: false,
    pasteAllowedStyleProps: ['font-family', 'font-size', 'color', 'text-decoration', 'font-weight'],
    imageUploadURL: '/assets/create_for_mobile',
    imageUploadParam: 'assets[]',
    imageUploadParams: {
      studio_id: studioId
    },
    imageManagerLoadURL:  '/assets',
    imageManagerPageSize: 40,
    imageManagerScrollOffset: 0,
    imageManagerLoadParams: {studio_id: studioId},
    toolbarButtons: {
      'moreText': {
        'buttons': ['bold', 'italic', 'fontSize', 'underline', 'subscript', 'superscript', 'strikeThrough', 'textColor', 'backgroundColor', 'inlineClass', 'clearFormatting']
      },
      'moreParagraph': {
        'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
      },
      'moreRich': {
        'buttons': ['insertLink', 'insertTable', 'insertImage', 'insertHR', 'rightToLeft', 'leftToRight'],
        'buttonsVisible': 3
      },
      'moreMisc': {
        'buttons': ['undo', 'redo', 'fullscreen', 'spellChecker', 'selectAll'],
        'align': 'right',
        'buttonsVisible': 5
      }
    },
    toolbarButtonsXS: ['bold', 'italic', 'underline'],
    linkStyles: {
      'post-body register-button black': 'Register Button'
    }
  });
}

window.getDimension = function() {
  document.getElementById('slide-editor').getAttribute('data-dimension');
}

window.isChinaSchool = ["teachfuture"].includes(window.location.hostname.split('.')[0]);

window.activateUppy = function () {
  if (!document.querySelector('.upload-btn')) return;
  if (window.uppy1) {
    window.uppy1.close();
  }

  window.uppy = Uppy.Core({
    autoProceed: true,
    restrictions: { allowedFileTypes: ['image/*', 'video/*', '.heic',  '.heif'] },
  });

  uppy.use(Uppy.FileInput, {
    target: '.upload-btn',
    replaceTargetContent: false,
    pretty: true
  });

  uppy.use(AwsS3Multipart, {
    limit: 4,
    companionUrl: isChinaSchool ? '/alibaba/' : '/'
  })

  class UppyS3DirectUpload extends Plugin {
    constructor (uppy, opts) {
      super(uppy, opts)
      this.id = opts.id || 'UppyS3DirectUpload'
      this.type = 'example'
      this.prepareUpload = this.prepareUpload.bind(this) // ← this!
    }

    prepareUpload (fileIDs) {
      const promises = fileIDs.map((fileID) => {
        const file = this.uppy.getFile(fileID)
        this.uppy.emit('preprocess-progress', file, {
          mode: 'indeterminate',
          message: 'Creating signed blobs'
        })

        return new Promise((resolve, reject) => {
          FileChecksum.create(file.data, (error, checksum) => {
            if(error) {
              console.log(error);
              return reject(error)
            } else {
              const blob = new BlobRecord(file.data, checksum, document.querySelector('meta[name=\'direct-upload-url\']').getAttribute('content'));
              blob.create(error => {
                if(error) {
                  console.log(error);
                  return reject(error)
                } else {
                  this.uppy.setFileMeta(file.id, {
                    blob: blob.toJSON()
                  });
                  return resolve();
                }
              });
            }
          });
        });
      })

      const emitPreprocessCompleteForAll = () => {
        fileIDs.forEach((fileID) => {
          const file = this.uppy.getFile(fileID)
          this.uppy.emit('preprocess-complete', file)
        })
      }

      // Why emit `preprocess-complete` for all files at once, instead of
      // above when each is processed?
      // Because it leads to StatusBar showing a weird “upload 6 files” button,
      // while waiting for all the files to complete pre-processing.
      return Promise.all(promises)
        .then(emitPreprocessCompleteForAll)
    }

    install () {
      this.uppy.addPreProcessor(this.prepareUpload)
    }

    uninstall () {
      this.uppy.removePreProcessor(this.prepareUpload)
    }
  }

  uppy.use(UppyS3DirectUpload, {});

  uppy.on('file-added', (file) => {
    let randomId = Math.floor(Math.random() * 100000) + 1;

    // Create div
    const template = `<div class="asset-container w-1/4 pb-10 pr-10 rounded temp-asset-container-${randomId}">
       <div class="asset relative bg-c4c4c4 border-4 border-white rounded selectable cursor-pointer hover:border-pink">
         <div class="w-full hd-ratio"></div>
         <div class="asset-progress absolute top-0 left-0 h-4 bg-pink"></div>
         <div class='absolute top-40px left-80px text-center text-xs'>
              <p class='status capitalize'>Waiting</p>
          </div>
       </div>
     </div>`

    const el = document.querySelector('.assets-infinite-scroll > div');
    el.prepend(htmlToElement(template));

    // Set meta data
    uppy.setFileMeta(file.id, {
      studio_id: document.getElementById('studio-id').value,
      temp_id: randomId,
    });
  });

  uppy.on('upload-progress', (file, progress) => {
    let tempId = parseInt(file.meta.temp_id);
    let percentage = progress.bytesUploaded / progress.bytesTotal * 100;
    let container = document.querySelector(`.temp-asset-container-${tempId}`);
    container.querySelector('.status').innerHTML = 'Uploading'
    container.querySelector(`.asset-progress`).style.width = `${percentage}%`;
  });

  uppy.on('upload-success', (file, response) => {
    let formData = new FormData();
    formData.append('studio_id', file.meta.studio_id);
    formData.append('blob_signed_id', file.meta.blob.signed_id);
    formData.append('key', new URL(response.uploadURL).pathname.split("/")[1]);
    if(!(file.meta.width === undefined))
      formData.append('width', file.meta.width);
    if(!(file.meta.height === undefined))
      formData.append('height', file.meta.height);
    fetch('/assets', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        let el = document.querySelector(`.temp-asset-container-${file.meta.temp_id}`);
        if (data.html) {
          el.classList.add(`asset-container-${data.asset_id}`);
          el.innerHTML = data.html;
          window.autoUpdatePendingAssets();
        } else {
          alert('Error in file');
          el.remove();
        }
      });
  });
};

window.activateUppyMobile = function() {
  if (!document.querySelector(".mobile-upload-btn")) {
    return;
  }

  window.uppy1 = Uppy.Core({
    autoProceed: true,
    restrictions: { allowedFileTypes: ['image/*', 'video/*'] }
  });
  uppy1.use(Uppy.FileInput, { target: '.mobile-upload-btn', replaceTargetContent: false, pretty: true });
  uppy1.use(Uppy.XHRUpload, {
    endpoint: '/assets/create_for_mobile',
    fieldName: 'assets[]',
    formData: true,
    metaFields: null
  });
  uppy1.on('file-added', (file) => {
    // Show progressbar
    let randomId = Math.floor(Math.random() * 100000) + 1;
    document.querySelector('.mobile-upload-btn').classList.add('hidden');
    document.querySelector('.loader').classList.remove('hidden');

    // Set meta data
    uppy1.setFileMeta(file.id, {
      studio_id: document.getElementById("studio-id").value,
      temp_id: randomId
    });
  });
  uppy1.on('complete', (result) => {
    let randomId = Math.floor(Math.random() * 100000) + 1;
    let assetIds = result.successful.map((file) => file.response.body.id);
    let presentationId = null;
    if (document.querySelector('.mobile-upload-btn')) {
      presentationId = document.querySelector('.mobile-upload-btn').getAttribute('data-presentation-id');
    }

    let url = `/items/create_via_assets_from_mobile?asset_ids=${assetIds}&random_id=${randomId}&presentation_id=${presentationId}`;

    fetch(url, {method: 'POST'}).then(function(response) {
      return response.text().then(function(text) {
        document.getElementById('edit-presentation-container').innerHTML = text;
        document.getElementById('edit-presentation-container').classList.add('mt-28');
        document.getElementById('edit-presentation-container').classList.add('-mb-14');

        window.zoomPresentation();

        document.querySelector('.mobile-upload-btn').setAttribute('data-presentation-id', document.getElementById(`presentation-id-${randomId}`).value);
        document.querySelector('.mobile-upload-btn').classList.remove('hidden');
        document.querySelector('.loader').classList.add('hidden');
        document.getElementById('post_attach_presentation_id').value = document.getElementById(`presentation-id-${randomId}`).value;a
      });
    });
  });
}

window.zoomPresentation = function() {
  let displayWindowSize = function() {
    // Zoom slides for mobile view
    const customPost = document.querySelector('.g4g-post .presentation-canvas, .x1-posts .post .presentation-canvas')
    let width = (customPost && customPost.clientWidth) || window.innerWidth;
    var slides = document.querySelectorAll('.presentation-zoom-level');
    if (width < 1200) {
      let canvasWidth = 1200;
      if (document.querySelector('#edit_post')) {
        canvasWidth = parseFloat(document.querySelector('#edit_post').offsetWidth);
      }
      else if (slides.length > 0) {
        canvasWidth = parseFloat(slides[0].closest('.presentation-canvas').offsetWidth);
      }
      slides.forEach((slide) => {
        slide.style.transform = `translate(0px, 0px) scale(${canvasWidth / 1200})`;
      });
    } else {
      slides.forEach((slide) => {
        const defaultZoom = slide.getAttribute('data-default-zoom') || 1;
        slide.style.transform = `translate(0px, 0px) scale(${defaultZoom})`;
      });
    }
  }

  displayWindowSize();
}

window.autoUpdatePendingAssets = function() {
  var pending_assets = document.querySelectorAll('.pending-asset:not(.auto-updating)');
  pending_assets.forEach((asset) => {
    asset.classList.add('auto-updating');
    let id = asset.getAttribute('data-asset-id');
    var intval = setInterval(function(){
      let selected = document.querySelector(`.asset-id-${id}`).classList.contains('selected');
      var url = `/assets/${id}?selected=${selected}`;
      fetch(url).then(function(response) {
        return response.text().then(function(text) {
          document.querySelector(`.asset-container-${id}`).innerHTML = text;
          if (!text.includes('pending-asset')) {
            clearInterval(intval);
          }
        });
      });
    }, 3000);
  });
}

window.closeMediaBrowser = function() {
  document.getElementById('modal-content').innerHTML = '';
  bodyScrollLock.clearAllBodyScrollLocks();
}

window.expandableTextArea = function() {
  var inputs = document.querySelectorAll('textarea.expandable');
  inputs.forEach((input) => {
    autosize(input);
    input.addEventListener('keydown', autosize);
  });

  function autosize(el) {
    let input;
    if (el.keyCode) {
      input = el.target;
    } else {
      input = el;
    }

    // TODO: Fix jump effect on update
    setTimeout(function(){
      // Reset input for getting actual height
      input.style.height = 'auto';
      input.style.lineHeight = '16px';
      input.style.padding = 0;

      let rows = parseInt(input.scrollHeight / 16);
      if (rows == 0) rows = 1;
      let totalHeight = (rows * 24) + 20;

      input.style.padding = '10px';
      input.style.lineHeight = '24px';
      input.style.height = totalHeight + 'px';
    }, 0);
  }
}

window.customSlider = function() {
  let inputs = document.querySelectorAll('.custom-slider');

  inputs.forEach((input) => {
    noUiSlider.create(input, {
      start: [input.getAttribute('data-value')],
      connect: 'lower',
      range: {
        'min': 0,
        'max': 100
      }
    }).on('update', function (values, handle) {
        var value = parseInt(values[handle]);
        var targetElement = input.closest('.slider-holder').querySelector('.slider-value');
        targetElement.value = value
    });

    // if (input.getAttribute('data-value') !== 'undefined') {
    //   // input.noUiSlider.set(input.getAttribute('data-value'));
    // }
  });
}

window.notice = function(message, duration = 3, clear = false, animation = true) {
  const animationClass = animation ? '' : 'notransition'
  let template = htmlToElement(`<div class="flash-notice ${animationClass}" role="alert" data-controller="flash-notice" data-flash-notice-duration="${duration}">
    <div class="flash-notice__content">
      ${message}
    </div>
  </div>`);

  const placeholder = document.querySelector('[data-behavior="flash_message_container"]');

  if(clear) {
    document.querySelectorAll('.flash-notice').forEach(el => el.remove());
  }

  insertBefore(template, placeholder);
}

window.animate = function(node, animation, prefix = 'animate__') {
// We create a Promise and return it
  return new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd() {
      node.classList.remove(`${prefix}animated`, animationName);
      node.removeEventListener('animationend', handleAnimationEnd);

      resolve('Animation ended');
    }

    node.addEventListener('animationend', handleAnimationEnd);
  });
}
